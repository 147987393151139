import React from "react"
import { graphql } from "gatsby"

import PageLayer from "~components/PageLayer"
import SEO from "~components/SEO"
import Masthead from "~components/Masthead"
import SectionBlock from "~components/SectionBlock"

import { mapCraft } from "./mappings"

const PagesTemplate = ({ data }) => {
  const { page } = mapCraft(data.craft)

  return (
    <PageLayer>
      <SEO title={page.title} metadata={page.metadata} />
      <Masthead {...page.masthead} />
      {page.sections.map((section, i) => (
        <SectionBlock key={i} {...section} />
      ))}
    </PageLayer>
  )
}

export const query = graphql`
  query($id: Int) {
    craft {
      page: entry(id: [$id], section: pages) {
        ...pageFields
        ... on Craft_PagesDefault {
          sections {
            ...textBlockFields
            ...htmlBlockFields
            ...quoteBlockFields
            ...processBlockFields
            ...insightBlockFields
            ...imageBlockFields
            ...doubleImageBlockFields
            ...comboBlockFields
            ...showcaseBlockFields
          }
          metadata {
            ...metadataFields
          }
        }
      }
    }
  }
`

export default PagesTemplate
